export const environment = {
    production: true,
    name: 'prod',
    storagePrefix: 'woonig-prod-',
    firebase: {
        apiKey: 'AIzaSyCNUBY8YO3R9r9vydbfJB3n8LhWCU5nnYE',
        authDomain: 'woonig-prod.firebaseapp.com',
        databaseURL: 'https://woonig-prod.firebaseio.com',
        projectId: 'woonig-prod',
        storageBucket: 'woonig-prod.appspot.com',
        messagingSenderId: '651021708378',
        timestampsInSnapshots: true,
        languageDocId: 'HbH3aZPcKE2M2ecsrYcc',
    },
    region: 'europe-west1',
    apiBase: 'https://woonig-prod.appspot.com/apiv1/',
    reCaptcha: {
        webKey: '6Ld9RNQUAAAAABt7NWmxiNK4oMuW7O-X-Oxp6xp2',
    },
    googleMapsApiKey: 'AIzaSyBLH-y8QtCrII3U11dvPfUSZmqanjV8EVg',
    store_link: {
        ios: 'itms-apps://apps.apple.com/ch/app/woonig-mieter-stwe/id1476238924',
        android: 'https://play.google.com/store/apps/details?id=ch.woonig.tenant',
    },
};
